import React, { Component } from 'react';
import './CFTV.css'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { Link } from "react-router-dom";

class CFTV extends Component {
    render() {
        return (
        <div className="bg-alarm">
          <Container>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={12} sm={12}>
                <Button variant="outlined" disableRipple={true}>
                  <img src="/images/camera.png" alt="icone camera" className="alarm-img" height="21px"/>
                  <b>CFTV</b>
                </Button >
              </Grid>
              <Grid item xs={12} sm={12}>
                <h2 className="alarm">
                  <b>Olá!</b> Vamos montar juntos um orçamento de câmeras?
                </h2>
              </Grid>
              <Grid item xs={12}>
                <Link to="/cftv-form">
                  <Button className="more-info">
                    VAMOS LÁ!
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    }
  }
  export default CFTV;
   