import React from 'react';
import Home from './Home';
import Alarm from './Alarm';
import AlarmOptions from './AlarmOptions'
import AlarmThanks from './Alarm-thanks'
import AlarmWpp from './Alarm-wpp'
import CFTV from './CFTV';
import CFTVform from './CFTV-form';
import CFTVThanks from './CFTV-thanks';
import Street from './Street';
import StreetInfo from './Street-info';
import StreetThanks from './Street-thanks';
import Vehicle from './Vehicle';
import VehicleInfo from './Vehicle-info';
import VehicleThanks from './Vehicle-thanks';
import AlarmForm from './AlarmForm';
import { Switch, Route, useLocation } from "react-router-dom";



function SwitchComponent() {
    const location = useLocation();
    return (
        <main style={{ position: "relative" }}>
            <Switch location={location} key={location.pathname}>
                <Route path="/" exact component={Home} />
                <Route path="/alarme-monitorado" exact component={Alarm} />
                <Route path="/alarme-opcoes" exact component={AlarmOptions} />
                <Route path="/alarme-obrigado" exact component={AlarmThanks} />
                <Route path="/alarme-whatsapp" exact component={AlarmWpp} />
                <Route path="/cftv" exact component={CFTV} />
                <Route path="/cftv-form" exact component={CFTVform} />
                <Route path="/cftv-obrigado" exact component={CFTVThanks} />
                <Route path="/rua-monitorada" exact component={Street} />
                <Route path="/rua-monitorada-info" exact component={StreetInfo} />
                <Route path="/rua-monitorada-obrigado" exact component={StreetThanks} />
                <Route path="/rastreamento-veicular" exact component={Vehicle} />
                <Route path="/rastreamento-veicular-info" exact component={VehicleInfo} />
                <Route path="/rastreamento-veicular-obrigado" exact component={VehicleThanks} />
                <Route path="/alarme-form" exact component={AlarmForm} />
            </Switch>
        </main>
    );
}

export default SwitchComponent;