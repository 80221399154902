import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { TextField } from '@material-ui/core';
class FormItem extends Component {
    
    state = {
        value: this.props.value
    }

  
    
    handleChange = (action) => {
        this.setState(state => {
            let value = state.value

            if (action === "ADD" && (!this.props.limit || (this.props.limit && this.props.limit > value))) {
                value = Number(value) + 1
            }
            if (action === "SUBTRACT") {
                if(value > 0) {
                    value = Number(value) - 1
                } 
            }
            return {
                value
            }
        }, () => {
            this.props.onChange(this.props.field, this.state.value)
        })
    }

    handleChangeValue = (event) => {
        const value = event.target.value;
        if(value < 100 && (!this.props.limit || (this.props.limit && this.props.limit > value))) {
            this.setState({ value })
            this.props.onChange(this.props.field, value)
        }
    }



    render() {
        const props = this.props
        return <Grid container alignItems="center" justify="space-between" style={{ marginBottom: "8px"}}>
                    <Grid item xs={6}>
                        <Grid container alignItems="center"> 
                            <Grid item xs={1}>
                            <div className="icon-form" style={{ backgroundColor: props.color }}></div>
                            </Grid>
                            <Grid item xs={11}>
                                <div className="item-name">
                                    <p><b>{props.name}</b></p>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={3} justify="flex-end" alignItems="center" style={{ color:"#F9F9F9" }}>
                            { this.state.value > 0 &&
                                <Grid item>
                                    <div onClick={() => {
                                        this.handleChange('SUBTRACT')
                                    }} style={{ width: 32}}>
                                        <RemoveIcon fontSize="small" style={{ cursor:"pointer" }}/>
                                    </div>
                                </Grid>
                            }
                            <Grid item>
                                <div className="border-qty">
                                    <div className="circle-qty">
                                       <TextField type="number" style={{ color: 'white', fontWeight: 'bold' }} value={this.state.value} onChange={this.handleChangeValue} inputProps={{ style: { textAlign: 'center', color: 'white' }}} />
                                    </div>
                                </div> 
                            </Grid>
                            { 
                             <Grid item>
                                <div onClick={() => {
                                    this.handleChange('ADD')
                                }} style={{ width: 32}}>
                                    <AddIcon fontSize="small" style={{ cursor:"pointer", opacity: !props.limit || props.limit > this.state.value ? 1 : 0 }}/>
                                </div>
                            </Grid>
                            }
                           
                        </Grid>
                    </Grid>
                </Grid>
}
}

export default FormItem;