import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import SwitchComponent from './SwitchComponent';


  function App() {
    return (
        <Router>
            <div className="App">
                <SwitchComponent/>
            </div>
        </Router>   
          );
  }

  export default App;