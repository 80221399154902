import React, { Component } from 'react';
import './Vehicle-info.css'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Link } from "react-router-dom";
import whatsappUrl from 'whatsapp-url';
import HeaderComponent from './HeaderComponent.js'

class VehicleInfo extends Component {

    generateBudget = () => {
        let message = 'Olá, gostaria de um orçamento para rastreamento veicular.'
        const link = whatsappUrl({phone: process.env.REACT_APP_PHONE_NUMBER, text: message})
        window.open(link)
    }

    render() {
      return (
        <div className="bg-vehicle-info">
            <div className="header">
                <HeaderComponent url_before="/" img_url="/images/car.png" alt="ícone carro" title="RASTREAMENTO VEICULAR"></HeaderComponent>
            </div>
            <div className="info-text">
                <Container>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={10} sm={6}>
                            <span>
                            Através do Rastreamento Veicular, o Cliente poderá localizar seu veículo em caso de furto ou roubo auxilindo em sua recuperação.
                            <br></br><br></br>
                            É um serviço muito recomendado para frota de veículos, com a utilização de diversos recursos que <b style={{ color: "#FABA0D" }}>facilitam a gestão empresarial </b>no controle dos veículos da frota.
                            </span>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Container>
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <Link to='./rastreamento-veicular-obrigado'>
                            <div onClick={ this.generateBudget } className="send">
                                QUERO UM ORÇAMENTO
                            </div>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
                <Container>
                <Grid container alignItems="center" justify="center">
                    <Grid item>
                        <img className="vs-logo" alt="logo vs" src="/images/vs-logo.png"/>
                    </Grid>
                </Grid>
            </Container>
        </div>
      );
    }
  }
  export default VehicleInfo;