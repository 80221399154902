import React, { Component } from 'react';
import './Street-info.css'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Link } from "react-router-dom";
import whatsappUrl from 'whatsapp-url';
import HeaderComponent from './HeaderComponent'


class StreetInfo extends Component {

    generateBudget = () => {
        let message = 'Olá, gostaria de um orçamento para rua monitorada.'
        const link = whatsappUrl({phone: process.env.REACT_APP_PHONE_NUMBER, text: message})
        window.open(link)
    }

    render() {
      return (
        <div className="bg-street-info">
            <HeaderComponent url_before="/" img_url="/images/camera-rua.png" alt="ícone camera" title="RUA MONITORADA"></HeaderComponent>
            <Container maxWidth="md">
                <div className="street-text">
                    <Container>
                        <Grid container justify="center">
                            <Grid item xs={10} sm={6}>
                                <span><b style={{ color: "#FABA0D" }}>Serviço colaborativo </b>entre os vizinhos.</span>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Container>
            <div className="info-text">
                <Container>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={10} sm={6}>
                            <span>
                            Nós vamos instalar câmeras na vizinhança e fornecer um aplicativo para que cada morador possa ver as imagens da rua e, em caso de necessidade, acionar um botão de pânico.
                            <br></br><br></br>
                            Quando o botão de pânico é acionado, a nossa central de monitoramento,<b style={{ color: "#FABA0D" }}> que funciona 24 horas</b>, começa a visualizar as imagens da rua para averiguação do que está ocorrendo e em caso de necessidade as forças policiais serão acionadas.
                            </span>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Container>
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <Link to='./rua-monitorada-obrigado'>
                            <div onClick={ this.generateBudget } className="send">
                                QUERO UM ORÇAMENTO
                            </div>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
                <Container>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <img className="vs-logo" alt="logo vs" src="/images/vs-logo.png"/>
                    </Grid>
                </Grid>
            </Container>
        </div>
      );
    }
  }
  export default StreetInfo;