import React, { Component } from 'react';
import './CFTV-thanks.css'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import HeaderComponent from './HeaderComponent'

class CFTVThanks extends Component {

    render() {
      return (
        <div className="bg-cftv-thanks"> 
            <HeaderComponent url_before="/cftv-form" img_url="/images/camera.png" alt="ícone camera" title="CFTV"></HeaderComponent>
            <Container maxWidth="md">
                <div className="cftv-text">
                    <Container>
                        <Grid container justify="center" direction="column" alignItems="center" spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <span><b style={{ color: "#FABA0D" }}>Obrigada por nos escolher!</b></span>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <span>Em breve nossa equipe irá responder você.</span>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Container>
        </div>
      );
    }
  }
  export default CFTVThanks;