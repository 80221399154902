import React, { Component } from 'react';
import './Home.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";


class Home extends Component {
  render() {
    return (
      <div className="bg">
        <Container style={{ paddingTop: "10px", paddingBottom: "10px", width: "90%" }}>
          <Grid container justify="flex-end">
            <Grid item className="vs-logo">
              <img src="/images/vs.png" alt="logo vs" style={{ height:"100%" }}/>
            </Grid>
          </Grid>
          <Grid container justify="space-between" >
            <Grid item md={4} xs={12}>
              <h2 className="title">Olá! Vamos montar seu <b>sistema de segurança?</b></h2>
              <h4 className="subtitle">Selecione o serviço de seu interesse.</h4>
              <img height="12px" src="/images/arrow.png" alt="seta para baixo" />
            </Grid>
            <Box
              component={Grid}
              item
              md={8}
              style={{ textAlign: "right" }}
              display={{ xs: "none", sm: "block" }}
            >
              <img height="550px" src="/images/bg.png" alt="background" />
            </Box>
          </Grid>
        <div className="buttons">
            <Grid container justify="space-between" alignItems="stretch" spacing={3}>
              <Grid item xs={12} sm={3}>
                <Link to="/alarme-monitorado">
                  <Button fullWidth>
                    Alarme monitorado
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Link to="/cftv">
                  <Button fullWidth>
                    CFTV
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Link to="/rua-monitorada">
                  <Button fullWidth>
                    Rua monitorada
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Link to="/rastreamento-veicular">
                  <Button fullWidth>
                    Rastreamento veicular
                  </Button>
                </Link>
              </Grid>
            </Grid>
        </div>
        </Container>
      </div>


    );
  }
}
export default Home;
