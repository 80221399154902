import React, { Component } from 'react';
import './Alarm.css'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { Link } from "react-router-dom";

class Alarm extends Component {
    render() {
      return (
        <div className="bg-alarm">
          <Container>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={12} sm={12}>
                <Button variant="outlined" disableRipple={true}>
                  <img src="/images/alarm.png" alt="icone alarme" className="alarm-img" height="21px"/>
                  <b>Alarme</b>
                </Button >
              </Grid>
              <Grid item xs={12} sm={12}>
                <h2 className="alarm">
                  <b>Legal!</b> Vamos começar com algumas perguntas:
                </h2>
              </Grid>
              <Grid item xs={12}>
                <Link to="/alarme-opcoes">
                  <Button className="more-info">
                    VAMOS LÁ!
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    }
  }
  export default Alarm;
   